import { useCallback, useEffect, useState } from "react";
import { Error, v1 } from "api/generated";
import { client } from "api/client";

export type UsersQueryData = v1.UsersList.ResponseBody["data"];

export const useUsersQuery = () => {
  const [data, setData] = useState<UsersQueryData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await client().usersList();

      if (!response.data) return;
      setData(response.data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    refetch: fetchData,
    error,
  };
};
