import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Input } from "components";
import { useDebounce } from "react-use";

type Props = {
  page: number;
  perPage: number;
  count: number;
  onChange: (page: number, perPage: number) => void;
};

const perPageOptions: number[] = [10, 20, 50, 100];

export const Pagination = (props: Props) => {
  const [page, setPage] = useState<number>(props.page);

  /* sync state with props */
  useEffect(() => setPage(props.page), [props.page]);

  const lastPage = useMemo(() => Math.ceil(props.count / props.perPage) || 1, [props.count, props.perPage]);

  /*
   * debounce during typing on the "page" input
   * */
  useDebounce(
    () => {
      if (page === props.page) return;
      return props.onChange(page <= lastPage ? page : lastPage, props.perPage);
    },
    1000,
    [page, lastPage, props.perPage]
  );

  const handleChangePage = useCallback((e: ChangeEvent<HTMLInputElement>) => setPage(parseFloat(e.target.value)), []);

  const handleChangePerPage = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      props.onChange(props.page, parseFloat(e.target.value));
    },
    [props]
  );

  const increasePage = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      props.onChange(props.page + 1, props.perPage);
    },
    [props]
  );

  const decreasePage = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      props.onChange(props.page - 1, props.perPage);
    },
    [props]
  );

  return (
    <div className="flex items-center">
      <div className="flex items-center text-sm">
        <span>Page</span>
        <div className="mx-2">
          <Button icon={"arrow_left"} onClick={decreasePage} disabled={props.page <= 1} />
          <Input
            onChange={handleChangePage}
            type={"number"}
            value={page}
            min={1}
            max={lastPage}
            className="w-16 mx-2"
          />
          <Button icon={"arrow_right"} onClick={increasePage} disabled={props.page >= lastPage} />
        </div>
        <span>of {lastPage}</span>
      </div>

      <div className="text-sm ml-6">
        <span>View</span>

        <div className="inline-block relative w-auto custom-select">
          <select
            onChange={handleChangePerPage}
            value={props.perPage}
            className="text-black appearance-none bg-white px-2 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline w-full truncate cursor-pointer"
          >
            {perPageOptions.map((item) => (
              <option key={item}>{item}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
            <div className="flex items-center justify-center custom-select__arrow-box">
              <Icon type={"arrow_down"} className="w-4 custom-select__arrow-box__icon" />
            </div>
          </div>
        </div>
        <span className="ml-2">records</span>
      </div>

      <div className="text-sm ml-6">
        <span>Total records:</span>
        <span className="text-black ml-2">{props.count}</span>
      </div>
    </div>
  );
};
