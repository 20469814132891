type GetRangeSliderValuesArgs = {
  min: number | undefined;
  max: number | undefined;
};

export const getRangeSliderValues = ({ min, max }: GetRangeSliderValuesArgs): [number, number] => {
  const minNumber = min === undefined ? 0 : min;
  const maxNumber = max === undefined ? 0 : max;
  return [minNumber, maxNumber];
};
