import React, { Ref } from "react";
import { cn } from "helpers";

type Props = {
  children: React.ReactNode;
  isVisible: boolean;
};

export const Sidebar = React.forwardRef(({ children, isVisible }: Props, ref: Ref<HTMLDivElement>) => (
  <div
    ref={ref}
    className={cn(
      "sidebar fixed top-0 right-0 flex flex-col items-center p-6 bg-white h-screen shadow-big rounded-tl-md rounded-bl-md",
      isVisible ? "visible" : "invisible"
    )}
    style={{ zIndex: 1 }}
  >
    {children}
  </div>
));
