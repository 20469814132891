import { createStore } from "global-hook-store";

export const userSelectStore = createStore(
  {
    id: -1,
  },
  {
    setId: (state, id: number) => ({ ...state, id: id }),
  }
);
