import React, { useMemo } from "react";
import { cn } from "helpers";
import { Icon, IconType } from "components/Icon";

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  icon?: IconType;
};

export const Button = ({ icon, className, children, ...rest }: Props) =>
  useMemo(
    () => (
      <button
        {...rest}
        className={cn(
          "c-button",
          {
            "c-button--cube": icon && !children,
          },
          className
        )}
      >
        {icon && <Icon type={icon} className={"c-button__icon"} />}
        {icon && children ? <span className="ml-2">{children}</span> : children}
      </button>
    ),
    [icon, className, children, rest]
  );
