import { Api } from "api/generated";
import * as storage from "api/storage";
import { getEnv } from "helpers";

const api = () => {
  return new Api({
    baseUrl: getEnv("REACT_APP_API_URL"),
    baseApiParams: {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage.getAuthToken()}`,
      },
    },
  });
};

export const client = () => api().v1;
