import React from "react";
import { cn } from "helpers";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
};

export const ButtonLink = ({ children, onClick, className }: Props) => {
  return (
    <button
      className={cn("bg-transparent p-1 text-brand-darker focus:outline-none border-none", className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
