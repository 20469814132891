import { useHistory } from "react-router-dom";
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { routes } from "routes";
import { useAuth } from "api";
import { useTitle } from "react-use";
import { TITLE_SUFFIX } from "consts";

export const useLogin = () => {
  const history = useHistory();

  useTitle("Login - " + TITLE_SUFFIX);

  const { login, isLoggedIn, loadingLogin } = useAuth();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const disableSubmit = useMemo(() => !username || !password, [username, password]);

  const handleError = useCallback((error: any) => console.error(error), []);
  const handleSuccess = useCallback(() => history.push(routes.dashboard), [history]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loadingLogin) return;

      return await login(username, password, handleSuccess, handleError);
    },
    [login, username, password, handleSuccess, handleError, loadingLogin]
  );

  const handleChangeUsername = useCallback((e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value), []);
  const handleChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value), []);

  /** If logged in, redirect to dashboard */
  useEffect(() => (isLoggedIn() ? history.push(routes.dashboard) : undefined), [isLoggedIn, history]);

  return {
    state: {
      loading: loadingLogin,
      username,
      password,
      disableSubmit,
    },
    handlers: {
      handleSubmit,

      handleChangeUsername,
      handleChangePassword,
    },
  };
};
