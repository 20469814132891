import React from "react";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { AudioPlayer } from "components/AudioPlayer";

type Props = {
  title: string;
  audioSrc: string;
  onClose: () => void;
};

export const AudioModal = ({ title, audioSrc, onClose }: Props) => {
  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col items-start bg-white shadow-big rounded-md overflow-hidden">
        {/* Modal Title */}
        <div className="flex items-center justify-between bg-brand w-full h-audio-modal-title pl-5 pr-2 flex-shrink-0">
          <span className="text-white truncate" title={title}>
            {title}
          </span>
          <button className="ml-2 p-3 focus:outline-none" onClick={onClose}>
            <Icon type={"close"} className="text-white w-3" />
          </button>
        </div>

        <div className="flex flex-col justify-center items-center w-full h-full p-10 md:px-20 md:py-12">
          <AudioPlayer src={audioSrc} />
        </div>
      </div>
    </Modal>
  );
};
