import { User } from "api";

/**
 * getUserDisplayName
 *
 * @param user
 */
export const getUserDisplayName = (user: User | undefined): string => {
  if (user?.first_name && user?.last_name) {
    return `${user.first_name} ${user.last_name}`;
  } else if (user?.name) {
    return user.name;
  } else {
    return "Unknown";
  }
};
