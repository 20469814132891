import React, { useEffect } from "react";
import { useMe, useUsersQuery } from "api/hooks";
import { getUserDisplayName } from "helpers";
import { Icon } from "components";
import useStore from "global-hook-store";
import { userSelectStore } from "store";

type Props = {
  children?: never;
};

export const UserSelect = (_: Props) => {
  const { data, loading, error } = useUsersQuery();
  const { state, actions } = useStore(userSelectStore);
  const { id, displayName } = useMe();

  useEffect(() => {
    if (state.id === -1) {
      actions.setId(parseFloat(`${id}`));
    }
  }, [state.id, actions, id]);

  return (
    <div className="flex items-center ml-auto">
      <span className="inline-flex flex-shrink-0 pr-2">Display data as</span>
      <div className="inline-block relative w-auto custom-select">
        <select
          value={state.id}
          onChange={(event) => {
            actions.setId(parseFloat(event.target.value));
          }}
          className="text-black appearance-none bg-white py-2 pl-8 rounded leading-tight focus:outline-none focus:shadow-outline w-full truncate cursor-pointer"
        >
          <option value={id}>{displayName}</option>
          {!loading && !error && data?.users
            ? data.users.map((user) => (
                <option value={user.id} key={user.id}>
                  {getUserDisplayName(user)}
                </option>
              ))
            : null}
        </select>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
          <div className="flex items-center justify-center custom-select__arrow-box">
            <Icon type={"arrow_down"} className="w-4 custom-select__arrow-box__icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
