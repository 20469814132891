import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useClickAway } from "react-use";

type Props = {
  onClose: () => void;
};

export const Modal: React.FC<Props> = ({ children, onClose }) => {
  const modalWrapperRef = useRef(document.createElement("div"));
  const modalBoxRef = useRef<HTMLDivElement>(null);

  useClickAway(modalBoxRef, () => onClose());

  useEffect(() => {
    const modalRoot = document.getElementById("modal");
    const modalWrapper = modalWrapperRef.current;

    if (modalRoot) {
      modalRoot.appendChild(modalWrapper);
    }

    return () => {
      if (modalRoot) {
        modalRoot.removeChild(modalWrapper);
      }
    };
  }, []);

  return createPortal(
    <div className="flex items-center justify-center absolute inset-0 bg-white bg-opacity-75 z-40">
      <div ref={modalBoxRef} className="max-w-full">
        {children}
      </div>
    </div>,
    modalWrapperRef.current
  );
};
