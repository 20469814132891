import React from "react";
import { useLogin } from "controllers";
import { Button, LoadingBar, Input, ErrorMessage } from "components";
import imgLogo from "assets/images/logo.png";
import { Header } from "containers";
import useStore from "global-hook-store";
import { loginStore } from "store";

export const Login = () => {
  const { state, handlers } = useLogin();
  const { state: loginStoreState } = useStore(loginStore);

  return (
    <div className="flex flex-col items-center min-h-screen">
      <Header />

      {loginStoreState.error && <ErrorMessage>Wrong email or password</ErrorMessage>}

      <div
        className="flex flex-col items-center justify-center w-full px-10 py-10 bg-gray-100 mt-4 md:mt-24"
        style={{ maxWidth: 600, minHeight: 400 }}
      >
        {!state.loading ? (
          <>
            <img src={imgLogo} className="mx-auto w-32" alt={"RingLocal"} />
            <form onSubmit={handlers.handleSubmit} className="mt-16" style={{ maxWidth: 300 }}>
              <Input
                value={state.username}
                name={"username"}
                onChange={handlers.handleChangeUsername}
                placeholder="E-mail"
                className="w-full"
              />

              <Input
                value={state.password}
                name={"password"}
                onChange={handlers.handleChangePassword}
                type={"password"}
                placeholder="Password"
                className="w-full mt-4"
              />

              {/** Submit button */}
              <div className="text-center mt-4">
                <Button type={"submit"} disabled={state.disableSubmit}>
                  Log in
                </Button>
              </div>
            </form>
          </>
        ) : (
          <LoadingBar />
        )}
      </div>
    </div>
  );
};
