import React from "react";
import { cn } from "helpers";
import { Icon } from "components";

type Props = {
  label: string | number;
  className?: string;
  onRemove?: () => void;
};

export const Tag = ({ label, className, onRemove }: Props) => (
  <div
    className={cn(
      "inline-flex items-center justify-center rounded-lg bg-gray-200 px-3 py-1",
      onRemove ? "cursor-pointer" : "cursor-default",
      className
    )}
    onClick={onRemove}
  >
    <span className="text-sm text-gray-900">{label}</span>
    {onRemove ? <Icon type={"close"} className="w-3 text-gray-600 ml-2" /> : null}
  </div>
);
