import React, { useCallback, useMemo, useState } from "react";
import { useAudio, useDebounce } from "react-use";
import { Button } from "components/Button";
import { SingleSlider } from "components/SingleSlider";

type Props = {
  src: string;
};

export const AudioPlayer = ({ src }: Props) => {
  const [audio, state, controls] = useAudio({
    src: src,
    autoPlay: false,
  });

  const [time, setTime] = useState(0);
  const [sliderIsTouching, setSliderIsTouching] = useState(false);

  /* compare time with buffered values */
  const loading = useMemo<boolean>(() => !state.buffered?.find((item) => time >= item.start && time <= item.end), [
    state.buffered,
    time,
  ]);

  const handleClickStop = useCallback(() => {
    controls.pause();
    controls.seek(0);
  }, [controls]);

  const handleChangeSlider = useCallback((newTime: number) => setTime(newTime), []);
  const handleBeforeChangeSlider = useCallback(() => setSliderIsTouching(true), []);
  const handleAfterChangeSlider = useCallback(() => {
    controls.seek(time);
    return setSliderIsTouching(false);
  }, [controls, time]);

  // debounce sync newTime with real audio time
  useDebounce(
    () => {
      if (sliderIsTouching || loading) return;
      return setTime(state.time);
    },
    50,
    [state.time, sliderIsTouching, loading]
  );

  return (
    <div className="w-full">
      {/* Progress Indicator */}
      <SingleSlider
        value={time}
        total={state.duration}
        onChange={handleChangeSlider}
        onBeforeChange={handleBeforeChangeSlider}
        onAfterChange={handleAfterChangeSlider}
        className="w-full"
      />
      <div className="flex">{audio}</div>

      {/* Controls */}
      <div className="w-full flex items-center mt-1">
        <span className="w-1/3 text-sm">{formatSeconds(time)}</span>

        <div className="w-1/3 flex justify-center -mx-1">
          <div className="p-1">
            {!state.paused ? (
              <Button onClick={controls.pause} icon={"pause"} />
            ) : (
              <Button onClick={controls.play} icon={"play"} />
            )}
          </div>
          <div className="p-1">
            <Button onClick={handleClickStop} icon={"stop"} />
          </div>
        </div>

        <span className="w-1/3 text-right text-sm">{state.duration ? formatSeconds(state.duration) : "--:--"}</span>
      </div>
    </div>
  );
};

/* format seconds to minutes with seconds */
const formatSeconds = (secondsTotal: number): string => {
  const minutes = Math.floor(secondsTotal / 60);
  const seconds = Math.floor(secondsTotal % 60);

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};
