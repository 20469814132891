import { useCallback, useState } from "react";
import { v1, client } from "api";
import { loginStore } from "store";
import useStore from "global-hook-store";

export type LoginMutationInput = v1.LoginCreate.RequestBody;
export type LoginMutationData = v1.LoginCreate.ResponseBody["data"];

export const useLoginMutation = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { actions } = useStore(loginStore);

  const mutate = useCallback(
    async (input: LoginMutationInput): Promise<LoginMutationData | undefined> => {
      setLoading(true);
      try {
        const { data } = await client().loginCreate(input);
        return data;
      } catch (e) {
        actions.setError(e);
      } finally {
        setLoading(false);
      }
    },
    [actions]
  );

  return {
    mutate,

    loading,
  };
};
