import React from "react";
import { Route, Switch } from "react-router-dom";
import * as Page from "pages";
import { routes } from "routes";
import { useDashboard } from "controllers";
import { Header } from "containers";

export const Dashboard = () => {
  useDashboard();

  return (
    <div className="flex flex-col w-screen h-screen max-h-screen">
      <Header />

      <main className="flex-grow overflow-auto">
        <Switch>
          <Route path={routes.calls} component={Page.Calls} exact={true} />
        </Switch>
      </main>
    </div>
  );
};
