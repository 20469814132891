import React, { useCallback } from "react";
import imgLogo from "assets/images/logo.png";
import { ButtonLink, Icon } from "components";
import { useAuth, useMe } from "api";
import { cn } from "helpers";
import { UserSelect } from "containers";

export const Header = () => {
  const { isLoggedIn, logout } = useAuth();
  const { displayName, permission } = useMe();

  const handleClickLogout = useCallback(async () => await logout(), [logout]);

  return (
    <header className="flex items-center w-full px-4 py-2 shadow-big" style={{ zIndex: 1 }}>
      <img src={imgLogo} className="h-8" alt={"RingLocal"} />

      {/* Data preview selector */}
      {isLoggedIn() && permission === "admin" && <UserSelect />}

      {/* User info */}
      {isLoggedIn() && (
        <div className={cn("flex items-center", permission === "admin" ? "ml-12" : "ml-auto")}>
          <Icon type={"profile"} className="text-brand w-8" />
          <span className="ml-2">{displayName}</span>
          <ButtonLink onClick={handleClickLogout} className="ml-4">
            Log Out
          </ButtonLink>
        </div>
      )}
    </header>
  );
};
