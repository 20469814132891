import { useCallback, useEffect, useState } from "react";
import { client, Error, v1 } from "api";

export type CallsQueryVariables = v1.UsersCallsDetail.RequestQuery & {
  id: number;
};
export type CallsQueryData = v1.UsersCallsDetail.ResponseBody["data"];

/*
 * useCallsQuery hook
 *
 * How use:
 * memoize variables before put in useCallsQuery
 * */
export const useCallsQuery = (variables: CallsQueryVariables) => {
  const [data, setData] = useState<CallsQueryData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { id, ...query } = variables;

      // -1 is initial id value
      if (id !== -1) {
        const response = await client().usersCallsDetail(id, query);

        if (!response.data) return;
        setData(response.data);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [variables]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,

    loading,
    refetch: fetchData,
    error,
  };
};
