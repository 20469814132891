import React, { useCallback } from "react";
import ReactSlider, { ReactSliderProps } from "react-slider";
import { cn } from "helpers";

/*
 * Wrapper for react-slider
 * doc: https://github.com/zillow/react-slider
 *
 * know issue:
 * console error "Using UNSAFE_componentWillReceiveProps in strict mode
 * is not recommended and may indicate bugs in your code."
 * source: https://github.com/zillow/react-slider/issues/193
 * */

type Props = {
  value: number;
  total: number;
  onChange?: (newValue: number) => void;
  onBeforeChange?: () => void;
  onAfterChange?: () => void;
  className?: string;
};

export const SingleSlider = ({
  value,
  total,
  onChange = () => {},
  onBeforeChange,
  onAfterChange,
  className,
}: Props) => {
  const handleChange: ReactSliderProps["onChange"] = useCallback(
    (newValue) => typeof newValue === "number" && onChange(newValue),
    [onChange]
  );

  return (
    <div className={className}>
      <ReactSlider
        className="h-3 w-full bg-gray-100 rounded-full"
        thumbClassName="h-3 w-3 rounded-full bg-brand-darker outline-none cursor-pointer"
        trackClassName=""
        renderTrack={(props, state) => (
          <span
            {...props}
            className={cn(
              "top-0 bottom-0 rounded-full cursor-pointer",
              state.index === 1 ? "bg-transparent" : "bg-brand"
            )}
          />
        )}
        min={0}
        max={total}
        value={value}
        ariaLabel={["Thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        onChange={handleChange}
        onBeforeChange={onBeforeChange}
        onAfterChange={onAfterChange}
        pearling
        minDistance={1}
      />
    </div>
  );
};
