import { User } from "api";

/** Local Storage keys */
export const LS_AUTH_TOKEN = "@ringlocal-reporting-webapp/auth-token";
export const LS_USER_PROFILE = "@ringlocal-reporting-webapp/user-profile";

/*
 * User
 * */
/* Authorization Token */
export const getAuthToken = (): string | undefined => {
  try {
    const token = window.localStorage.getItem(LS_AUTH_TOKEN);
    return typeof token === "string" && token.length ? JSON.parse(token) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const setAuthToken = (value: string): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, JSON.stringify(value));
  } catch (e) {}
};

export const clearAuthToken = (): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, "");
  } catch (e) {}
};

/* Profile */
export const getUserProfile = (): User | undefined => {
  try {
    const token = window.localStorage.getItem(LS_USER_PROFILE);
    return typeof token === "string" && token.length ? JSON.parse(token) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const setUserProfile = (value: User): void => {
  try {
    window.localStorage.setItem(LS_USER_PROFILE, JSON.stringify(value));
  } catch (e) {}
};

export const clearUserProfile = (): void => {
  try {
    window.localStorage.setItem(LS_USER_PROFILE, "");
  } catch (e) {}
};
