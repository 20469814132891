import { useCallback, useState } from "react";
import { client, Error, v1 } from "api";

export type CallsLazyQueryVariables = v1.UsersCallsDetail.RequestQuery & {
  id: number;
};
export type CallsLazyQueryData = v1.UsersCallsDetail.ResponseBody["data"];

export const useCallsLazyQuery = () => {
  const [data, setData] = useState<CallsLazyQueryData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const load = useCallback(async (variables: CallsLazyQueryVariables): Promise<CallsLazyQueryData> => {
    setLoading(true);
    try {
      const { id, ...query } = variables;

      // -1 is initial id value
      if (id !== -1) {
        const response = await client().usersCallsDetail(id, query);

        if (!response.data) return;
        setData(response.data);
        return response.data;
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return { load, data, loading, error };
};
