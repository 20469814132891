import React, { useMemo } from "react";
import { cn } from "helpers";
import { IconType } from "components/Icon";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  icon?: IconType;
};

export const Input = ({ className, ...rest }: Props) =>
  useMemo(() => <input {...rest} className={cn("c-input", className)} />, [className, rest]);
