/* tslint:disable */
/* eslint-disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type Code = number;

export type Status = string;

export type Input = string;

export type Message = string;

export type Token = string;

export interface Chart {
  date?: string;
  calls?: number;
}

export interface Error {
  status?: Status;
  code?: Code;
  input?: Input;
  message?: Message;
}

export interface Call {
  id?: number;
  company?: string;
  tags?: Tag[];
  to?: string;
  city?: string;
  state?: string;
  name?: string;
  from?: string;
  duration?: number;
  recording?: string;
  recording_player?: string;
  start_time?: string;
  lead_status?: string;
  keywords?: string;
  keywords_spotted?: string[];
  highlights?: string[];
  source_name?: string;
  transcription?: string;
  sub_company?: string;
  company_tag?: string;
}

export type Tag = string;

export interface User {
  id?: number;
  name?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  permission?: "user" | "admin";
}

export namespace v1 {
  /**
   * @tags accounts
   * @name loginCreate
   * @summary User login
   * @request POST:/v1/login
   * @response `200` `{ status?: Status, code?: Code, data?: { user?: User, token?: Token } }` Success
   * @response `400` `Error` Bad Request
   * @response `401` `Error` Unauthorized
   */
  export namespace LoginCreate {
    export type RequestQuery = {};
    export type RequestBody = { email: string; password: string };
    export type ResponseBody = { status?: Status; code?: Code; data?: { user?: User; token?: Token } };
  }

  /**
   * @tags accounts
   * @name logoutList
   * @summary User logout
   * @request GET:/v1/logout
   * @secure
   * @response `200` `{ status?: Status, code?: Code, message?: Message }` Success
   * @response `400` `Error` Bad Request
   * @response `401` `Error` Unauthorized
   */
  export namespace LogoutList {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = { status?: Status; code?: Code; message?: Message };
  }

  /**
   * @tags users
   * @name usersCallsDetail
   * @summary Calls
   * @request GET:/v1/users/{id}/calls
   * @secure
   * @response `200` `{ status?: Status, code?: Code, data?: { per_page?: number, page?: number, last_page?: number, total_records?: number, calls?: (Call)[], chart?: (Chart)[] } }` Success
   * @response `400` `Error` Bad Request
   * @response `401` `Error` Unauthorized
   */
  export namespace UsersCallsDetail {
    export type RequestQuery = {
      page?: number;
      per_page?: number;
      sort?:
        | "company"
        | "to"
        | "city"
        | "state"
        | "name"
        | "from"
        | "duration"
        | "start_time"
        | "lead_status"
        | "source_name"
        | "sub_company"
        | "company_tag";
      order?: "desc" | "asc";
      date_start?: string;
      date_end?: string;
      duration_min?: number;
      duration_max?: number;
      company?: string;
      source_name?: string;
      name?: string;
      city?: string;
      state?: string;
      from?: string;
      to?: string;
      lead_status?: string;
      highlights?: string[];
      keywords_spotted?: string[];
      tags?: string[];
      transcription?: boolean;
      sub_company?: string;
      company_tag?: string;
    };
    export type RequestBody = never;
    export type ResponseBody = {
      status?: Status;
      code?: Code;
      data?: {
        per_page?: number;
        page?: number;
        last_page?: number;
        total_records?: number;
        calls?: Call[];
        chart?: Chart[];
      };
    };
  }

  /**
   * @tags users
   * @name usersList
   * @summary Users
   * @request GET:/v1/users
   * @secure
   * @response `200` `{ status?: Status, code?: Code, data?: { users?: (User)[] } }` Success
   * @response `400` `Error` Bad Request
   * @response `401` `Error` Unauthorized
   */
  export namespace UsersList {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = { status?: Status; code?: Code; data?: { users?: User[] } };
  }
}

export type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

type ApiConfig<SecurityDataType> = {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
};

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
type TPromise<ResolveType, RejectType = any> = Omit<Promise<ResolveType>, "then" | "catch"> & {
  then<TResult1 = ResolveType, TResult2 = never>(
    onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): TPromise<TResult1 | TResult2, RejectType>;
  catch<TResult = never>(
    onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
  ): TPromise<ResolveType | TResult, RejectType>;
};

const enum BodyType {
  Json,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "https://client.ringlocal.com/be/api/";
  private securityData: SecurityDataType = null as any;
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"] = (() => {}) as any;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor({ baseUrl, baseApiParams, securityWorker }: ApiConfig<SecurityDataType> = {}) {
    this.baseUrl = baseUrl || this.baseUrl;
    this.baseApiParams = baseApiParams || this.baseApiParams;
    this.securityWorker = securityWorker || this.securityWorker;
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(response: Response): TPromise<T, E> =>
    response
      .json()
      .then((data) => data)
      .catch((e) => response.text);

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): TPromise<T, E> =>
    fetch(`${this.baseUrl}${path}`, {
      // @ts-ignore
      ...this.mergeRequestOptions(params, (secureByDefault || secure) && this.securityWorker(this.securityData)),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    }).then(async (response) => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
}

/**
 * @title RingLocal Reporting API
 * @version 1.0.0
 * @baseUrl https://client.ringlocal.com/be/api/
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * @tags accounts
     * @name loginCreate
     * @summary User login
     * @request POST:/v1/login
     * @response `200` `{ status?: Status, code?: Code, data?: { user?: User, token?: Token } }` Success
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     */
    loginCreate: (data: { email: string; password: string }, params?: RequestParams) =>
      this.request<{ status?: Status; code?: Code; data?: { user?: User; token?: Token } }, Error>(
        `/v1/login`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags accounts
     * @name logoutList
     * @summary User logout
     * @request GET:/v1/logout
     * @secure
     * @response `200` `{ status?: Status, code?: Code, message?: Message }` Success
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     */
    logoutList: (params?: RequestParams) =>
      this.request<{ status?: Status; code?: Code; message?: Message }, Error>(
        `/v1/logout`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags users
     * @name usersCallsDetail
     * @summary Calls
     * @request GET:/v1/users/{id}/calls
     * @secure
     * @response `200` `{ status?: Status, code?: Code, data?: { per_page?: number, page?: number, last_page?: number, total_records?: number, calls?: (Call)[], chart?: (Chart)[] } }` Success
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     */
    usersCallsDetail: (
      id: number,
      query?: {
        page?: number;
        per_page?: number;
        sort?:
          | "company"
          | "to"
          | "city"
          | "state"
          | "name"
          | "from"
          | "duration"
          | "start_time"
          | "lead_status"
          | "source_name"
          | "sub_company"
          | "company_tag";
        order?: "desc" | "asc";
        date_start?: string;
        date_end?: string;
        duration_min?: number;
        duration_max?: number;
        company?: string;
        source_name?: string;
        name?: string;
        city?: string;
        state?: string;
        from?: string;
        to?: string;
        lead_status?: string;
        highlights?: string[];
        keywords_spotted?: string[];
        tags?: string[];
        transcription?: boolean;
        sub_company?: string;
        company_tag?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<
        {
          status?: Status;
          code?: Code;
          data?: {
            per_page?: number;
            page?: number;
            last_page?: number;
            total_records?: number;
            calls?: Call[];
            chart?: Chart[];
          };
        },
        Error
      >(`/v1/users/${id}/calls${this.addQueryParams(query)}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags users
     * @name usersList
     * @summary Users
     * @request GET:/v1/users
     * @secure
     * @response `200` `{ status?: Status, code?: Code, data?: { users?: (User)[] } }` Success
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     */
    usersList: (params?: RequestParams) =>
      this.request<{ status?: Status; code?: Code; data?: { users?: User[] } }, Error>(
        `/v1/users`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
}
