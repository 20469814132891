import React from "react";

type Props = {
  children: string;
};

export const ErrorMessage = ({ children }: Props) => {
  return (
    <div className="flex justify-center w-screen h-0 fixed">
      <div className="transform translate-y-20">
        <span className="text-white text-sm bg-red rounded-md px-3 py-2">{children}</span>
      </div>
    </div>
  );
};
