import { Call } from "api";
import { getFormattedStartTime } from "helpers/getFormattedStartTime";

export const getCsvDataForCalls = (calls: Call[]) => {
  const isColumnShown = (item: string) => calls[0] && item in calls[0];

  const headers = [];

  isColumnShown("start_time") && headers.push("Start Time");
  isColumnShown("company_tag") && headers.push("Company Name");
  isColumnShown("source_name") && headers.push("Number Name");
  isColumnShown("name") && headers.push("Caller Name");
  isColumnShown("city") && headers.push("Caller City");
  isColumnShown("state") && headers.push("Caller State");
  isColumnShown("from") && headers.push("Caller Phone Number");
  isColumnShown("duration") && headers.push("Duration (s)");
  isColumnShown("lead_status") && headers.push("Qualified");
  isColumnShown("to") && headers.push("Agent Number");
  isColumnShown("recording") && headers.push("Call Audio");
  isColumnShown("tags") && headers.push("Tags");

  const data = calls.reduce((csvData, call) => {
    const csvRow = [];
    isColumnShown("start_time") && csvRow.push(getFormattedStartTime(call.start_time));
    isColumnShown("company_tag") && csvRow.push(call.company_tag);
    isColumnShown("source_name") && csvRow.push(call.source_name);
    isColumnShown("name") && csvRow.push(call.name);
    isColumnShown("city") && csvRow.push(call.city);
    isColumnShown("state") && csvRow.push(call.state);
    isColumnShown("from") && csvRow.push(call.from);
    isColumnShown("duration") && csvRow.push(call.duration);
    isColumnShown("lead_status") && csvRow.push(call.lead_status);
    isColumnShown("to") && csvRow.push(call.to);
    isColumnShown("recording") && csvRow.push(call.recording);
    isColumnShown("tags") && call.tags && csvRow.push(call.tags.toString());

    return [...csvData, csvRow];
  }, [] as (string | number | never[] | undefined)[][]);

  return [headers, ...data];
};
