const base = {
  root: "/",
  login: "/login",
  dashboard: "/dashboard",
};

const dashboard = {
  calls: base.dashboard + "/calls",
};

export const routes = {
  ...base,
  ...dashboard,
};

export const setRouteParams = <Params extends { [key: string]: string }>(route: string, params: Params): string => {
  let url = route;
  for (let key of Object.keys(params)) {
    url = url.replace(`:${key}`, params[key].toString());
  }

  return url;
};

export const getRoute = {
  root: () => routes.root,
  login: () => routes.login,
  dashboard: () => routes.dashboard,

  calls: () => routes.calls,
};
