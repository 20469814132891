import { useMemo } from "react";
import * as storage from "api/storage";
import { getUserDisplayName } from "helpers";

export const useMe = () => {
  const user = useMemo(() => storage.getUserProfile(), []);

  const displayName = useMemo(() => getUserDisplayName(user), [user]);
  const id = useMemo(() => user?.id || -1, [user]);
  const permission = useMemo(() => user?.permission, [user]);

  return {
    displayName,
    id,
    permission,
  };
};
