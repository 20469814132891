import { useCallback } from "react";
import { routes } from "routes";
import { Error, useLoginMutation, useLogoutMutation } from "api";
import * as storage from "api/storage";

export const useAuth = () => {
  const { mutate: mutateLogin, loading: loadingLogin } = useLoginMutation();
  const { mutate: mutateLogout, loading: loadingLogout } = useLogoutMutation();

  const login = useCallback(
    async (username: string, password: string, onSuccess: () => void, onError: (e: Error) => void) => {
      try {
        const data = await mutateLogin({
          email: username,
          password: password,
        });

        if (!data?.token || !data?.user) return;

        await storage.setAuthToken(data.token);
        await storage.setUserProfile(data.user);

        onSuccess();
      } catch (e) {
        onError(e);
      }
    },
    [mutateLogin]
  );

  const logout = useCallback(async () => {
    try {
      await mutateLogout();
    } catch (e) {
      // TODO: handle error, remove console.log
      console.log(e);
    } finally {
      storage.clearAuthToken();
      storage.clearUserProfile();
      window.sessionStorage.removeItem("filter");
      window.sessionStorage.removeItem("date");

      window.location.replace(routes.login);
    }
  }, [mutateLogout]);

  const isLoggedIn = useCallback((): boolean => !!storage.getAuthToken(), []);

  return {
    loadingLogin,
    loadingLogout,
    login,
    logout,
    isLoggedIn,
  };
};
