import { useCallback, useState } from "react";
import { v1, Error, client } from "api";

export type LogoutMutationData = v1.LogoutList.ResponseBody["message"];

export const useLogoutMutation = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const mutate = useCallback(async (): Promise<LogoutMutationData | undefined> => {
    setLoading(true);
    try {
      const { message } = await client().logoutList();
      return message;
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    mutate,

    loading,
    error,
  };
};
