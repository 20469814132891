import { useCallback, useMemo, useRef, useState } from "react";
import { DateRangeProps, Range } from "react-date-range";
import { format, startOfMonth } from "date-fns";
import { useTitle } from "react-use";
import { TITLE_SUFFIX } from "consts";
import { Call, useCallsLazyQuery } from "api";
import { CallsQueryVariables, useCallsQuery } from "api/hooks/useCallsQuery";
import { IDataTableColumn } from "react-data-table-component";
import useStore from "global-hook-store";
import { filterStore } from "store";
import { getDataFromSessionStorage } from "helpers";

type Sort = {
  order: "asc" | "desc";
  key: string;
};

const dateDataFromSessionStorage: { startDate: string; endDate: string } | undefined = getDataFromSessionStorage(
  "date"
);

const defaultRange: Range = {
  startDate: dateDataFromSessionStorage?.startDate
    ? new Date(dateDataFromSessionStorage.startDate)
    : startOfMonth(new Date()),
  endDate: dateDataFromSessionStorage?.endDate ? new Date(dateDataFromSessionStorage.endDate) : new Date(),
  key: "selection",
};

export const useCalls = (id: number) => {
  useTitle("Calls - " + TITLE_SUFFIX);

  /* sorter */
  const [sorter, setSorter] = useState<Sort>();
  /* pagination */
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  /* date picker*/
  const [range, setRange] = useState(defaultRange);
  const [showDatePicker, setShowDatePicker] = useState(false);

  /* filters */
  const { state: filterState } = useStore(filterStore);

  /* query variables */
  const variables = useMemo<CallsQueryVariables>(() => {
    let result: CallsQueryVariables = {
      id,
      page,
      per_page: perPage,
      date_start: format(range.startDate, "yyyy-MM-dd"),
      date_end: format(range.endDate, "yyyy-MM-dd"),
      ...filterState,
    };

    if (sorter?.order) result = { ...result, order: sorter.order };
    if (typeof sorter?.key === "string")
      result = {
        ...result,
        // TODO: solve annotation
        // @ts-ignore
        sort: sorter.key,
      };

    return result;
  }, [page, perPage, range, sorter, id, filterState]);

  /* query variables for csv data */
  const variablesForCsv = useMemo<CallsQueryVariables>(() => {
    let result: CallsQueryVariables = {
      id,
      per_page: Number.MAX_SAFE_INTEGER,
      date_start: format(range.startDate, "yyyy-MM-dd"),
      date_end: format(range.endDate, "yyyy-MM-dd"),
      ...filterState,
    };

    if (sorter?.order) result = { ...result, order: sorter.order };
    if (typeof sorter?.key === "string")
      result = {
        ...result,
        // TODO: solve annotation
        // @ts-ignore
        sort: sorter.key,
      };

    return result;
  }, [id, filterState, range, sorter]);

  const { data, loading } = useCallsQuery(variables);
  const { load: loadCsvCalls, data: csvData, loading: csvLoading } = useCallsLazyQuery();

  const calls = useMemo(() => data?.calls || [], [data]);
  const csvCalls = useMemo(() => csvData?.calls || [], [csvData]);
  const chart = useMemo(() => data?.chart || [], [data]);
  const pagination = useMemo(
    () => ({
      page: data?.page || 0,
      perPage: data?.per_page || 0,
      count: data?.total_records || 0,
    }),
    [data]
  );

  const prevColumn = useRef<string | undefined>(undefined);
  const handleChangeSort = useCallback((column: IDataTableColumn<Call>, order: Sort["order"]) => {
    if (typeof column.selector !== "string") return;

    if (prevColumn.current) {
      const headerCell = document.querySelector(`#column-${prevColumn.current}`)?.parentElement;
      if (headerCell) {
        headerCell.classList.remove("bg-brand");
        headerCell.classList.remove("bg-opacity-25");
      }
    }

    const headerCell = document.querySelector(`#column-${column.selector}`)?.parentElement;
    if (headerCell) {
      headerCell.classList.add("bg-brand");
      headerCell.classList.add("bg-opacity-25");
    }
    prevColumn.current = column.selector;

    setSorter({
      order: order,
      key: column.selector,
    });
  }, []);

  const handleChangePage = useCallback((page: number, perPage: number) => {
    setPage(page);
    setPerPage(perPage);
  }, []);

  const handleChangeDateRange = useCallback<DateRangeProps["onChange"]>((value) => {
    setRange(value.selection);
    window.sessionStorage.setItem(
      "date",
      JSON.stringify({
        startDate: value.selection.startDate,
        endDate: value.selection.endDate,
      })
    );
  }, []);
  const handleToggleDatePicker = useCallback(() => setShowDatePicker(!showDatePicker), [showDatePicker]);

  const loadCsvData = useCallback(async () => loadCsvCalls(variablesForCsv), [loadCsvCalls, variablesForCsv]);

  return {
    data: {
      calls,
      chart,
      pagination,
      csvCalls,
    },
    state: {
      loading,
      csvLoading,
      range,
      showDatePicker,
    },
    handlers: {
      handleChangeSort,
      handleChangePage,

      handleChangeDateRange,
      handleToggleDatePicker,

      loadCsvData,
    },
  };
};
