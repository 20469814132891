import { useEffect } from "react";
import { useAuth } from "api";
import { routes } from "routes";
import { useHistory } from "react-router-dom";

export const useDashboard = () => {
  const history = useHistory();

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    /** If not logged in, redirect to login */
    if (!isLoggedIn()) return history.replace(routes.login);

    /** Set default page */
    history.replace(routes.calls);
  }, [history, isLoggedIn]);

  return {};
};
