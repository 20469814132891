import React from "react";
import { cn } from "helpers";

type Props = {
  className?: string;
};

export const LoadingBar = ({ className }: Props) => (
  <div
    className={cn("relative inline-flex bg-brand bg-opacity-25 rounded-full overflow-hidden", className)}
    style={{ height: 4, width: 100 }}
  >
    <span className="relative h-full bg-brand rounded-full animation-loadingBar" />
  </div>
);
