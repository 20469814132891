import { createStore } from "global-hook-store";
import { CallsQueryVariables } from "api/hooks";
import { getDataFromSessionStorage } from "helpers";

export type Filters = Pick<
  CallsQueryVariables,
  | "company_tag"
  | "source_name"
  | "name"
  | "city"
  | "state"
  | "from"
  | "to"
  | "lead_status"
  | "duration_min"
  | "duration_max"
  | "tags"
  | "keywords_spotted"
  | "highlights"
>;

const filterDataFromSessionStorage: Filters | undefined = getDataFromSessionStorage("filter");

const initialFiltersState: Filters = {
  company_tag: filterDataFromSessionStorage?.company_tag,
  source_name: filterDataFromSessionStorage?.source_name,
  name: filterDataFromSessionStorage?.name,
  city: filterDataFromSessionStorage?.city,
  state: filterDataFromSessionStorage?.state,
  from: filterDataFromSessionStorage?.from,
  to: filterDataFromSessionStorage?.to,
  lead_status: filterDataFromSessionStorage?.lead_status,
  duration_min: filterDataFromSessionStorage?.duration_min,
  duration_max: filterDataFromSessionStorage?.duration_max,
  tags: filterDataFromSessionStorage?.tags,
  keywords_spotted: filterDataFromSessionStorage?.keywords_spotted,
  highlights: filterDataFromSessionStorage?.highlights,
};

export const filterStore = createStore(
  {
    ...initialFiltersState,
  },
  {
    setFilter: (state, payload: { id: string; value: any }[]) => {
      let newState = { ...state };
      payload.forEach((filter) => {
        newState = { ...newState, [filter.id]: filter.value };
      });
      window.sessionStorage.setItem("filter", JSON.stringify(newState));
      return newState;
    },
    resetFilter: (_) => {
      window.sessionStorage.removeItem("filter");
      return {
        company_tag: undefined,
        source_name: undefined,
        name: undefined,
        city: undefined,
        state: undefined,
        from: undefined,
        to: undefined,
        lead_status: undefined,
        duration_min: undefined,
        duration_max: undefined,
        tags: undefined,
        keywords_spotted: undefined,
        highlights: undefined,
      };
    },
  }
);
