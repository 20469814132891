import React, { Fragment } from "react";
import { Input } from "components/Input";
import { RangeSlider } from "components/RangeSlider";
import { ButtonLink } from "components/ButtonLink";
import { getRangeSliderValues } from "helpers";
import { useFilter } from "controllers/useFilter";
import { Tag } from "components";
import { Filters, filterStore } from "store";
import useStore from "global-hook-store";

export type FilterPayload = {
  id: keyof Filters;
  value: any;
};

type Props = {
  children?: never;
};

export const Filter = (_: Props) => {
  const { state, handlers } = useFilter();
  const { actions } = useStore(filterStore);

  const resetFilter = () => {
    actions.resetFilter();
    handlers.handleFilterReset();
  };

  return (
    <Fragment>
      <div className="flex w-full h-full my-6 overflow-y-scroll">
        <form onSubmit={(event) => event.preventDefault()} className="flex flex-col items-start w-full">
          <label className="text-sm w-full">
            Company Name
            <Input
              className="w-full"
              value={state.companyTag || ""}
              onChange={(event) => handlers.handleCompanyTagChange(event.target.value)}
            />
          </label>

          <label className="text-sm mt-8 w-full">
            Number Name
            <Input
              className="w-full"
              value={state.numberName || ""}
              onChange={(event) => handlers.handleNumberNameChange(event.target.value)}
            />
          </label>

          <label className="text-sm mt-8 w-full">
            Caller Name
            <Input
              className="w-full"
              value={state.callerName || ""}
              onChange={(event) => handlers.handleCallerNameChange(event.target.value)}
            />
          </label>

          <div className="flex justify-between w-full">
            <div className="flex flex-col w-2/3 mr-3">
              <label className="text-sm mt-8 w-full">
                Caller City
                <Input
                  className="w-full"
                  value={state.callerCity || ""}
                  onChange={(event) => handlers.handleCallerCityChange(event.target.value)}
                />
              </label>
            </div>
            <div className="flex flex-col w-1/3 ml-3">
              <label className="text-sm mt-8 w-full">
                Caller State
                <Input
                  className="w-full"
                  value={state.callerState || ""}
                  onChange={(event) => handlers.handleCallerStateChange(event.target.value)}
                />
              </label>
            </div>
          </div>

          <div className="flex justify-between w-full">
            <div className="flex flex-col w-1/2 mr-3">
              <label className="text-sm mt-8 w-full">
                Caller Phone Number
                <Input
                  className="w-full"
                  value={state.callerPhoneNumber || ""}
                  onChange={(event) => handlers.handleCallerPhoneNumberChange(event.target.value)}
                />
              </label>
            </div>
            <div className="flex flex-col w-1/2 ml-3">
              <label className="text-sm mt-8 w-full">
                Agent Number
                <Input
                  className="w-full"
                  value={state.agentNumber || ""}
                  onChange={(event) => handlers.handleAgentNumberChange(event.target.value)}
                />
              </label>
            </div>
          </div>

          <label className="text-sm mt-8 w-full">
            Qualified
            <Input
              className="w-full"
              value={state.qualified || ""}
              onChange={(event) => handlers.handleQualifiedChange(event.target.value)}
            />
          </label>

          <label className="text-sm mt-8">Duration</label>
          <RangeSlider
            className="w-full mt-3"
            values={getRangeSliderValues({ min: state.durationMin, max: state.durationMax })}
            range={[0, 7200]}
            onChange={handlers.handleRangeSliderChange}
          />
          <div className="flex justify-between w-full mt-3">
            <div className="flex justify-start items-center w-1/3">
              <span className="text-sm mr-3">From</span>
              <Input
                className="w-20"
                type="number"
                min="0"
                max="7200"
                value={state.durationMin ?? ""}
                onChange={(event) => handlers.handleDurationMinChange(event.target.value)}
              />
            </div>
            <div className="flex justify-end items-center w-1/3">
              <span className="text-sm mr-3">To</span>
              <Input
                className="w-20"
                type="number"
                min="0"
                max="7200"
                value={state.durationMax ?? ""}
                onChange={(event) => handlers.handleDurationMaxChange(event.target.value)}
              />
            </div>
          </div>

          <label className="text-sm mt-8 w-full">
            Tags
            <Input
              className="w-full"
              value={state.tag || ""}
              onChange={(event) => handlers.handleTagChange(event.target.value)}
              onKeyUp={(event) => handlers.handleTagAddition(event.key)}
            />
          </label>
          <div className="flex flex-wrap w-full">
            {state.tags &&
              state.tags.map((tag, index) => (
                <Tag
                  key={`tag-${index}`}
                  label={tag}
                  className="mt-3 mr-3"
                  onRemove={() => handlers.handleTagRemoving(index)}
                />
              ))}
          </div>

          <label className="text-sm mt-8 w-full">
            Keywords Spotting
            <Input
              className="w-full"
              value={state.keyword}
              onChange={(event) => handlers.handleKeywordChange(event.target.value)}
              onKeyUp={(event) => handlers.handleKeywordAddition(event.key)}
            />
          </label>
          <div className="flex flex-wrap w-full">
            {state.keywords &&
              state.keywords.map((keyword, index) => (
                <Tag
                  key={`keyword-${index}`}
                  label={keyword}
                  className="mt-3 mr-3"
                  onRemove={() => handlers.handleKeywordRemoving(index)}
                />
              ))}
          </div>

          <label className="text-sm mt-8 w-full">
            Call Highlights
            <Input
              className="w-full"
              value={state.highlight}
              onChange={(event) => handlers.handleHighlightChange(event.target.value)}
              onKeyUp={(event) => handlers.handleHighlightAddition(event.key)}
            />
          </label>
          <div className="flex flex-wrap w-full">
            {state.highlights &&
              state.highlights.map((highlight, index) => (
                <Tag
                  key={`highlight-${index}`}
                  label={highlight}
                  className="mt-3 mr-3"
                  onRemove={() => handlers.handleHighlightRemoving(index)}
                />
              ))}
          </div>
        </form>
      </div>
      <div className="flex justify-center w-full flex-shrink-0">
        <ButtonLink onClick={() => resetFilter()}>Reset Filter</ButtonLink>
      </div>
    </Fragment>
  );
};
