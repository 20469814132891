import { createStore } from "global-hook-store";

export const loginStore = createStore(
  {
    error: undefined as Error | undefined,
  },
  {
    setError: (state, error: Error | undefined) => ({ ...state, error: error }),
  }
);
