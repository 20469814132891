import { useReducer } from "react";
import { useDebounce } from "react-use";
import useStore from "global-hook-store";
import { Filters, filterStore } from "store";
import isEqual from "lodash.isequal";
import { getDataFromSessionStorage } from "helpers";

type InitialState = {
  companyTag: string | undefined;
  numberName: string | undefined;
  callerName: string | undefined;
  callerCity: string | undefined;
  callerState: string | undefined;
  callerPhoneNumber: string | undefined;
  agentNumber: string | undefined;
  qualified: string | undefined;
  durationMin: number | undefined;
  durationMax: number | undefined;
  tag: string | undefined;
  tags: string[] | undefined;
  keyword: string | undefined;
  keywords: string[] | undefined;
  highlight: string | undefined;
  highlights: string[] | undefined;
};

const filterDataFromSessionStorage: Filters | undefined = getDataFromSessionStorage("filter");

const initialState: InitialState = {
  companyTag: filterDataFromSessionStorage?.company_tag,
  numberName: filterDataFromSessionStorage?.source_name,
  callerName: filterDataFromSessionStorage?.name,
  callerCity: filterDataFromSessionStorage?.city,
  callerState: filterDataFromSessionStorage?.state,
  callerPhoneNumber: filterDataFromSessionStorage?.from,
  agentNumber: filterDataFromSessionStorage?.to,
  qualified: filterDataFromSessionStorage?.lead_status,
  durationMin: filterDataFromSessionStorage?.duration_min,
  durationMax: filterDataFromSessionStorage?.duration_max,
  tag: undefined,
  tags: filterDataFromSessionStorage?.tags,
  keyword: undefined,
  keywords: filterDataFromSessionStorage?.keywords_spotted,
  highlight: undefined,
  highlights: filterDataFromSessionStorage?.highlights,
};

export const actionTypes = {
  setCompanyTag: "SET_COMPANY_TAG",
  setNumberName: "SET_NUMBER_NAME",
  setCallerName: "SET_CALLER_NAME",
  setCallerCity: "SET_CALLER_CITY",
  setCallerState: "SET_CALLER_STATE",
  setCallerPhoneNumber: "SET_CALLER_PHONE_NUMBER",
  setAgentNumber: "SET_AGENT_NUMBER",
  setQualified: "SET_QUALIFIED",
  setDurationMin: "SET_DURATION_MIN",
  setDurationMax: "SET_DURATION_MAX",
  setTag: "SET_TAG",
  setTags: "SET_TAGS",
  removeTag: "REMOVE_TAG",
  setKeyword: "SET_KEYWORD",
  setKeywords: "SET_KEYWORDS",
  removeKeyword: "REMOVE_KEYWORD",
  setHighlight: "SET_HIGHLIGHT",
  setHighlights: "SET_HIGHLIGHTS",
  removeHighlight: "REMOVE_HIGHLIGHT",
  reset: "RESET",
};

type ActionData = {
  companyTag?: string;
  numberName?: string;
  callerName?: string;
  callerCity?: string;
  callerState?: string;
  callerPhoneNumber?: string;
  agentNumber?: string;
  qualified?: string;
  durationMin?: number;
  durationMax?: number;
  tag?: string;
  tags?: string[];
  keyword?: string;
  keywords?: string[];
  highlight?: string;
  highlights?: string[];
  index?: number;
};

type Action<Data> = {
  type: string;
  data?: Data;
};

const reducer = (state: InitialState, action: Action<ActionData>) => {
  switch (action.type) {
    /**
     * Company Tag
     */
    case actionTypes.setCompanyTag: {
      if (action?.data?.companyTag !== undefined) {
        return { ...state, companyTag: action.data.companyTag };
      }
      throw new Error("action.data.companyTag is missing");
    }

    /**
     * Number Name
     */
    case actionTypes.setNumberName: {
      if (action?.data?.numberName !== undefined) {
        return { ...state, numberName: action.data.numberName };
      }
      throw new Error("action.data.numberName is missing");
    }

    /**
     * Caller Name
     */
    case actionTypes.setCallerName: {
      if (action?.data?.callerName !== undefined) {
        return { ...state, callerName: action.data.callerName };
      }
      throw new Error("action.data.callerName is missing");
    }

    /**
     * Caller City
     */
    case actionTypes.setCallerCity: {
      if (action?.data?.callerCity !== undefined) {
        return { ...state, callerCity: action.data.callerCity };
      }
      throw new Error("action.data.callerCity is missing");
    }

    /**
     * Caller State
     */
    case actionTypes.setCallerState: {
      if (action?.data?.callerState !== undefined) {
        return { ...state, callerState: action.data.callerState };
      }
      throw new Error("action.data.callerState is missing");
    }

    /**
     * Caller Phone Number
     */
    case actionTypes.setCallerPhoneNumber: {
      if (action?.data?.callerPhoneNumber !== undefined) {
        return { ...state, callerPhoneNumber: action.data.callerPhoneNumber };
      }
      throw new Error("action.data.callerPhoneNumber is missing");
    }

    /**
     * Agent Number
     */
    case actionTypes.setAgentNumber: {
      if (action?.data?.agentNumber !== undefined) {
        return { ...state, agentNumber: action.data.agentNumber };
      }
      throw new Error("action.data.agentNumber is missing");
    }

    /**
     * Qualified
     */
    case actionTypes.setQualified: {
      if (action?.data?.qualified !== undefined) {
        return { ...state, qualified: action.data.qualified };
      }
      throw new Error("action.data.qualified is missing");
    }

    /**
     * Duration
     */
    case actionTypes.setDurationMin: {
      if (action?.data?.durationMin !== undefined) {
        return { ...state, durationMin: action.data.durationMin };
      }
      throw new Error("action.data.durationMin is missing");
    }
    case actionTypes.setDurationMax: {
      if (action?.data?.durationMax !== undefined) {
        return { ...state, durationMax: action.data.durationMax };
      }
      throw new Error("action.data.durationMax is missing");
    }

    /**
     * Tags
     */
    case actionTypes.setTag: {
      if (action?.data?.tag !== undefined) {
        return { ...state, tag: action.data.tag };
      }
      throw new Error("action.data.tag is missing");
    }
    case actionTypes.setTags: {
      if (action?.data?.tags !== undefined) {
        return { ...state, tags: action.data.tags, tag: "" };
      }
      throw new Error("action.data.tags is missing");
    }
    case actionTypes.removeTag: {
      if (action?.data?.index !== undefined) {
        const newTags = state.tags ? state.tags.filter((_, tagIndex) => tagIndex !== action?.data?.index) : [];
        return { ...state, tags: newTags };
      }
      throw new Error("action.data.index is missing in removeTag action");
    }

    /**
     * Keywords Spotting
     */
    case actionTypes.setKeyword: {
      if (action?.data?.keyword !== undefined) {
        return { ...state, keyword: action.data.keyword };
      }
      throw new Error("action.data.keyword is missing");
    }
    case actionTypes.setKeywords: {
      if (action?.data?.keywords !== undefined) {
        return { ...state, keywords: action.data.keywords, keyword: "" };
      }
      throw new Error("action.data.keywords is missing");
    }
    case actionTypes.removeKeyword: {
      if (action?.data?.index !== undefined) {
        const newKeywords = state.keywords
          ? state.keywords.filter((_, keywordIndex) => keywordIndex !== action?.data?.index)
          : [];
        return { ...state, keywords: newKeywords };
      }
      throw new Error("action.data.index is missing in removeKeyword action");
    }

    /**
     * Call Highlights
     */
    case actionTypes.setHighlight: {
      if (action?.data?.highlight !== undefined) {
        return { ...state, highlight: action.data.highlight };
      }
      throw new Error("action.data.highlight is missing");
    }
    case actionTypes.setHighlights: {
      if (action?.data?.highlights !== undefined) {
        return { ...state, highlights: action.data.highlights, highlight: "" };
      }
      throw new Error("action.data.highlights is missing");
    }
    case actionTypes.removeHighlight: {
      if (action?.data?.index !== undefined) {
        const newHighlights = state.highlights
          ? state.highlights.filter((_, highlightIndex) => highlightIndex !== action?.data?.index)
          : [];
        return { ...state, highlights: newHighlights };
      }
      throw new Error("action.data.index is missing in removeHighlight action");
    }

    /**
     * Reset
     */
    case actionTypes.reset: {
      return {
        companyTag: undefined,
        numberName: undefined,
        callerName: undefined,
        callerCity: undefined,
        callerState: undefined,
        callerPhoneNumber: undefined,
        agentNumber: undefined,
        qualified: undefined,
        durationMin: undefined,
        durationMax: undefined,
        tag: undefined,
        tags: undefined,
        keyword: undefined,
        keywords: undefined,
        highlight: undefined,
        highlights: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export const useFilter = () => {
  const [inputState, dispatch] = useReducer(reducer, initialState);
  const { state: filterState, actions } = useStore(filterStore);

  const handleCompanyTagChange = (value: string) => {
    dispatch({ type: actionTypes.setCompanyTag, data: { companyTag: value } });
  };

  useDebounce(
    () => {
      if (inputState.companyTag === undefined || inputState.companyTag === filterState.company_tag) return;
      actions.setFilter([{ id: "company_tag", value: inputState.companyTag !== "" ? inputState.companyTag : undefined }]);
    },
    1000,
    [inputState.companyTag]
  );

  const handleNumberNameChange = (value: string) => {
    dispatch({ type: actionTypes.setNumberName, data: { numberName: value } });
  };

  useDebounce(
    () => {
      if (inputState.numberName === undefined || inputState.numberName === filterState.source_name) return;
      actions.setFilter([
        { id: "source_name", value: inputState.numberName !== "" ? inputState.numberName : undefined },
      ]);
    },
    1000,
    [inputState.numberName]
  );

  const handleCallerNameChange = (value: string) => {
    dispatch({ type: actionTypes.setCallerName, data: { callerName: value } });
  };

  useDebounce(
    () => {
      if (inputState.callerName === undefined || inputState.callerName === filterState.name) return;
      actions.setFilter([{ id: "name", value: inputState.callerName !== "" ? inputState.callerName : undefined }]);
    },
    1000,
    [inputState.callerName]
  );

  const handleCallerCityChange = (value: string) => {
    dispatch({ type: actionTypes.setCallerCity, data: { callerCity: value } });
  };

  useDebounce(
    () => {
      if (inputState.callerCity === undefined || inputState.callerCity === filterState.city) return;
      actions.setFilter([{ id: "city", value: inputState.callerCity !== "" ? inputState.callerCity : undefined }]);
    },
    1000,
    [inputState.callerCity]
  );

  const handleCallerStateChange = (value: string) => {
    dispatch({ type: actionTypes.setCallerState, data: { callerState: value } });
  };

  useDebounce(
    () => {
      if (inputState.callerState === undefined || inputState.callerState === filterState.state) return;
      actions.setFilter([{ id: "state", value: inputState.callerState !== "" ? inputState.callerState : undefined }]);
    },
    1000,
    [inputState.callerState]
  );

  const handleCallerPhoneNumberChange = (value: string) => {
    dispatch({ type: actionTypes.setCallerPhoneNumber, data: { callerPhoneNumber: value } });
  };

  useDebounce(
    () => {
      if (inputState.callerPhoneNumber === undefined || inputState.callerPhoneNumber === filterState.from) return;
      actions.setFilter([
        { id: "from", value: inputState.callerPhoneNumber !== "" ? inputState.callerPhoneNumber : undefined },
      ]);
    },
    1000,
    [inputState.callerPhoneNumber]
  );

  const handleAgentNumberChange = (value: string) => {
    dispatch({ type: actionTypes.setAgentNumber, data: { agentNumber: value } });
  };

  useDebounce(
    () => {
      if (inputState.agentNumber === undefined || inputState.agentNumber === filterState.to) return;
      actions.setFilter([{ id: "to", value: inputState.agentNumber !== "" ? inputState.agentNumber : undefined }]);
    },
    1000,
    [inputState.agentNumber]
  );

  const handleQualifiedChange = (value: string) => {
    dispatch({ type: actionTypes.setQualified, data: { qualified: value } });
  };

  useDebounce(
    () => {
      if (inputState.qualified === undefined || inputState.qualified === filterState.lead_status) return;
      actions.setFilter([{ id: "lead_status", value: inputState.qualified !== "" ? inputState.qualified : undefined }]);
    },
    1000,
    [inputState.qualified]
  );

  const handleRangeSliderChange = (values: [number, number]) => {
    const [min, max] = values;
    dispatch({ type: actionTypes.setDurationMin, data: { durationMin: min } });
    dispatch({ type: actionTypes.setDurationMax, data: { durationMax: max } });
  };

  const handleDurationMinChange = (value: string) => {
    dispatch({ type: actionTypes.setDurationMin, data: { durationMin: parseFloat(value) } });
  };

  const handleDurationMaxChange = (value: string) => {
    dispatch({ type: actionTypes.setDurationMax, data: { durationMax: parseFloat(value) } });
  };

  useDebounce(
    () => {
      if (
        (inputState.durationMin === undefined && inputState.durationMax === undefined) ||
        (inputState.durationMin === filterState.duration_min && inputState.durationMax === filterState.duration_max)
      )
        return;
      actions.setFilter([
        {
          id: "duration_min",
          value: inputState.durationMin,
        },
        {
          id: "duration_max",
          value: inputState.durationMax,
        },
      ]);
    },
    1000,
    [inputState.durationMin, inputState.durationMax]
  );

  const handleTagChange = (value: string) => {
    dispatch({ type: actionTypes.setTag, data: { tag: value } });
  };

  const handleTagAddition = (key: string) => {
    if (key === "Enter" && inputState.tag) {
      const newTags = inputState.tags ? [...inputState.tags, inputState.tag] : [inputState.tag];
      dispatch({ type: actionTypes.setTags, data: { tags: newTags } });
    }
  };

  const handleTagRemoving = (index: number) => {
    dispatch({ type: actionTypes.removeTag, data: { index } });
  };

  useDebounce(
    () => {
      if (inputState.tags === undefined || isEqual(inputState.tags, filterState.tags)) return;
      actions.setFilter([{ id: "tags", value: inputState.tags?.length !== 0 ? inputState.tags : undefined }]);
    },
    1000,
    [inputState.tags]
  );

  const handleKeywordChange = (value: string) => {
    dispatch({ type: actionTypes.setKeyword, data: { keyword: value } });
  };

  const handleKeywordAddition = (key: string) => {
    if (key === "Enter" && inputState.keyword) {
      const newKeywords = inputState.keywords ? [...inputState.keywords, inputState.keyword] : [inputState.keyword];
      dispatch({ type: actionTypes.setKeywords, data: { keywords: newKeywords } });
    }
  };
  const handleKeywordRemoving = (index: number) => {
    dispatch({ type: actionTypes.removeKeyword, data: { index } });
  };

  useDebounce(
    () => {
      if (inputState.keywords === undefined || isEqual(inputState.keywords, filterState.keywords_spotted)) return;
      actions.setFilter([
        { id: "keywords_spotted", value: inputState.keywords?.length !== 0 ? inputState.keywords : undefined },
      ]);
    },
    1000,
    [inputState.keywords]
  );

  const handleHighlightChange = (value: string) => {
    dispatch({ type: actionTypes.setHighlight, data: { highlight: value } });
  };

  const handleHighlightAddition = (key: string) => {
    if (key === "Enter" && inputState.highlight) {
      const newHighlights = inputState.highlights
        ? [...inputState.highlights, inputState.highlight]
        : [inputState.highlight];
      dispatch({ type: actionTypes.setHighlights, data: { highlights: newHighlights } });
    }
  };
  const handleHighlightRemoving = (index: number) => {
    dispatch({ type: actionTypes.removeHighlight, data: { index } });
  };

  useDebounce(
    () => {
      if (inputState.highlights === undefined || isEqual(inputState.highlights, filterState.highlights)) return;
      actions.setFilter([
        { id: "highlights", value: inputState.highlights?.length !== 0 ? inputState.highlights : undefined },
      ]);
    },
    1000,
    [inputState.highlights]
  );

  const handleFilterReset = () => {
    dispatch({ type: actionTypes.reset });
  };

  return {
    state: inputState,
    dispatch,
    handlers: {
      handleCompanyTagChange,
      handleNumberNameChange,
      handleCallerNameChange,
      handleCallerCityChange,
      handleCallerStateChange,
      handleCallerPhoneNumberChange,
      handleAgentNumberChange,
      handleQualifiedChange,
      handleRangeSliderChange,
      handleDurationMinChange,
      handleDurationMaxChange,
      handleTagChange,
      handleTagAddition,
      handleTagRemoving,
      handleKeywordChange,
      handleKeywordAddition,
      handleKeywordRemoving,
      handleHighlightChange,
      handleHighlightAddition,
      handleHighlightRemoving,
      handleFilterReset,
    },
  };
};
