import React, { CSSProperties } from "react";
import { ReactComponent as IconArrowDown } from "assets/icons/icon_arrow_down.svg";
import { ReactComponent as IconArrowLeft } from "assets/icons/icon_arrow_left.svg";
import { ReactComponent as IconArrowRight } from "assets/icons/icon_arrow_right.svg";
import { ReactComponent as IconArrowUp } from "assets/icons/icon_arrow_up.svg";
import { ReactComponent as IconClose } from "assets/icons/icon_close.svg";
import { ReactComponent as IconDownload } from "assets/icons/icon_download.svg";
import { ReactComponent as IconFilter } from "assets/icons/icon_filter.svg";
import { ReactComponent as IconHeadphones } from "assets/icons/icon_headphones.svg";
import { ReactComponent as IconPlay } from "assets/icons/icon_play.svg";
import { ReactComponent as IconPause } from "assets/icons/icon_pause.svg";
import { ReactComponent as IconProfile } from "assets/icons/icon_profile.svg";
import { ReactComponent as IconStop } from "assets/icons/icon_stop.svg";
import { cn } from "helpers";

const icons = {
  arrow_down: IconArrowDown,
  arrow_left: IconArrowLeft,
  arrow_right: IconArrowRight,
  arrow_up: IconArrowUp,
  close: IconClose,
  download: IconDownload,
  filter: IconFilter,
  headphones: IconHeadphones,
  play: IconPlay,
  pause: IconPause,
  profile: IconProfile,
  stop: IconStop,
};

export type IconType = keyof typeof icons;

type Props = {
  type: IconType;
  className?: string;
  style?: CSSProperties;
};

/*
 * Color can be controlled via tailwind text color utility.
 * More info: https://tailwindcss.com/docs/fill/#usage
 *
 * example:
 * <Icon type={"trash"} className="h-10 text-red-500" />
 * */
export const Icon: React.FC<Props> = ({ type, className, style }) => {
  const Icon = icons[type];
  return <Icon className={cn("fill-current", className)} style={style} />;
};
