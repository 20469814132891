import { Filters } from "store";

export const isFilterApplied = (filters: Filters): boolean => {
  let isFilterApplied = false;

  if (filters.company_tag && filters.company_tag !== "") isFilterApplied = true;
  if (filters.source_name && filters.source_name !== "") isFilterApplied = true;
  if (filters.name && filters.name !== "") isFilterApplied = true;
  if (filters.city && filters.city !== "") isFilterApplied = true;
  if (filters.state && filters.state !== "") isFilterApplied = true;
  if (filters.from && filters.from !== "") isFilterApplied = true;
  if (filters.to && filters.to !== "") isFilterApplied = true;
  if (filters.lead_status && filters.lead_status !== "") isFilterApplied = true;
  if (filters.duration_min !== undefined) isFilterApplied = true;
  if (filters.duration_max !== undefined) isFilterApplied = true;
  if (filters.tags && filters.tags.length > 0) isFilterApplied = true;
  if (filters.keywords_spotted && filters.keywords_spotted.length > 0) isFilterApplied = true;
  if (filters.highlights && filters.highlights.length > 0) isFilterApplied = true;

  return isFilterApplied;
};
