import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { routes } from "routes";
import * as Page from "pages";
import { Dashboard } from "layouts";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Redirect from={routes.root} to={routes.login} exact={true} />

          <Route path={routes.login} component={Page.Login} />

          <Route path={routes.dashboard} component={Dashboard} />
        </Switch>
      </BrowserRouter>

      {/* default react-toast config*/}
      <ToastContainer
        position={"top-center"}
        autoClose={4000}
        closeButton={false}
        hideProgressBar={true}
        className={"w-auto"}
        toastClassName="c-toast"
      />
    </>
  );
}

export default App;
