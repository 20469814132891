import React, { useCallback, useMemo } from "react";
import ReactSlider, { ReactSliderProps } from "react-slider";
import { cn } from "helpers";

/*
 * Wrapper for react-slider
 * doc: https://github.com/zillow/react-slider
 *
 * know issue:
 * console error "Using UNSAFE_componentWillReceiveProps in strict mode
 * is not recommended and may indicate bugs in your code."
 * source: https://github.com/zillow/react-slider/issues/193
 * */

type Props = {
  values?: ValueType;
  onChange?: (newValues: ValueType) => void;
  range?: [number, number];
  className?: string;
};

type ValueType = [number, number];

const defaultRange: ValueType = [0, 100];

export const RangeSlider = ({ values = defaultRange, onChange = () => {}, range, className }: Props) => {
  const _range = useMemo<ValueType>(() => range || defaultRange, [range]);

  const handleChange: ReactSliderProps["onChange"] = useCallback(
    (newValues) => {
      const _newValues = validateValues(newValues);
      if (!_newValues) return;

      onChange(_newValues);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <ReactSlider
        className="h-4 w-full bg-gray-100 rounded-full"
        thumbClassName="h-4 w-4 rounded-full bg-brand-darker outline-none cursor-pointer"
        trackClassName=""
        renderTrack={(props, state) => (
          <span
            {...props}
            className={cn(
              "top-0 bottom-0 rounded-full cursor-pointer",
              state.index === 1 ? "bg-brand" : "bg-transparent"
            )}
          />
        )}
        min={_range[0]}
        max={_range[1]}
        value={values}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        onChange={handleChange}
        pearling
        minDistance={1}
      />
    </div>
  );
};

const validateValues = (value: ValueType): ValueType | undefined => {
  if (Array.isArray(value) && value.length === 2) return value as ValueType;
  return;
};
