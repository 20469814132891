import React, { useCallback, useMemo, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useCalls } from "controllers";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TW_COLOR_BRAND, TW_FONT_SIZE_SM } from "styles";
import { Call, useMe } from "api";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { Button, Filter, Icon, LoadingBar, Sidebar, Tag } from "components";
import { Pagination } from "components/Pagination";
import { useClickAway } from "react-use";
import { cn, getCsvDataForCalls, getFormattedStartTime, isFilterApplied } from "helpers";
import useStore from "global-hook-store";
import { filterStore, userSelectStore } from "store";
import { CSVLink } from "react-csv";
import { AudioModal } from "containers";

export const Calls = () => {
  const { id, permission } = useMe();
  const { state: userSelectState } = useStore(userSelectStore);

  const userId = permission === "admin" ? userSelectState.id : id;

  const { data, state, handlers } = useCalls(userId);

  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const { state: filters } = useStore(filterStore);

  /* hide datepicker when user click outside */
  const datepickerRef = useRef(null);
  useClickAway(datepickerRef, handlers.handleToggleDatePicker);

  /* hide sidebar when user click outside */
  const sidebarRef = useRef(null);
  useClickAway(sidebarRef, () => setIsFilterOpened(false));

  const tableData = useMemo<Call[]>(() => data.calls, [data.calls]);
  const csvData = useMemo(() => getCsvDataForCalls(data.csvCalls), [data.csvCalls]);

  const canOmit = useCallback((item: string) => tableData[0] && !(item in tableData[0]), [tableData]);
  const tableColumns = useMemo<IDataTableColumn<Call>[]>(
    () => [
      {
        name: "",
        selector: "recording_player",
        width: "48px",
        style: { padding: 0, justifyContent: "center" },
        cell: (row) => <ListenRecordingCell row={row} />,
        omit: canOmit("recording_player"),
      },
      {
        name: "Start Time",
        selector: "start_time",
        sortable: true,
        width: "200px",
        cell: (row) => (
          <span title={getFormattedStartTime(row.start_time)} className="text-black text-base">
            {getFormattedStartTime(row.start_time)}
          </span>
        ),
        omit: canOmit("start_time"),
      },
      {
        name: "Company Name",
        selector: "company_tag",
        sortable: true,
        minWidth: "196px",
        cell: (row) => (
          <span title={row.company_tag} className="text-black text-base">
            {row.company_tag}
          </span>
        ),
        omit: canOmit("company_tag"),
      },
      {
        name: "Number Name",
        selector: "source_name",
        sortable: true,
        minWidth: "196px",
        cell: (row) => (
          <span title={row.source_name} className="text-black text-base">
            {row.source_name}
          </span>
        ),
        omit: canOmit("source_name"),
      },
      {
        name: "Caller Name",
        selector: "name",
        sortable: true,
        minWidth: "196px",
        cell: (row) => (
          <span title={row.name} className="text-black text-base">
            {row.name}
          </span>
        ),
        omit: canOmit("name"),
      },
      {
        name: "Caller City",
        selector: "city",
        sortable: true,
        minWidth: "196px",
        cell: (row) => (
          <span title={row.city} className="text-black text-base">
            {row.city}
          </span>
        ),
        omit: canOmit("city"),
      },
      {
        name: "Caller State",
        selector: "state",
        sortable: true,
        width: "126px",
        cell: (row) => (
          <span title={row.state} className="text-black text-base">
            {row.state}
          </span>
        ),
        omit: canOmit("state"),
      },
      {
        name: "Caller Phone Number",
        selector: "from",
        sortable: true,
        width: "178px",
        cell: (row) => (
          <span title={row.from} className="text-black text-base">
            {row.from}
          </span>
        ),
        omit: canOmit("from"),
      },
      {
        name: "Duration (s)",
        selector: "duration",
        sortable: true,
        width: "120px",
        cell: (row) => (
          <span title={`${row.duration}`} className="text-black text-base">
            {row.duration}
          </span>
        ),
        omit: canOmit("duration"),
      },
      {
        name: "Qualified",
        selector: "lead_status",
        sortable: true,
        minWidth: "196px",
        cell: (row) => (
          <span title={row.lead_status} className="text-black text-base">
            {row.lead_status}
          </span>
        ),
        omit: canOmit("lead_status"),
      },
      {
        name: "Agent Number",
        selector: "to",
        sortable: true,
        width: "146px",
        cell: (row) => (
          <span title={row.to} className="text-black text-base">
            {row.to}
          </span>
        ),
        omit: canOmit("to"),
      },
    ],
    [canOmit]
  );

  const csvLinkRef = useRef<any>(null);
  const handleExportToCsv = useCallback(async () => {
    await handlers.loadCsvData();

    // force click on the CSVLink component
    if (csvLinkRef.current) csvLinkRef.current.link.click();
  }, [handlers]);

  return (
    <div>
      <div className={cn(styleContentRow, "flex justify-between items-center py-3")}>
        <h1 className="c-typo-xl">Calls</h1>

        <div className="inline-flex">
          <div className="relative ml-2">
            <button
              onClick={handlers.handleToggleDatePicker}
              className="flex items-center p-1 datepicker-button focus:outline-none"
            >
              {state.range.startDate.toLocaleDateString()} - {state.range.endDate.toLocaleDateString()}
              <div className="flex m-auto items-center justify-center datepicker-button__arrow-box ml-4">
                <Icon
                  type={state.showDatePicker ? "arrow_up" : "arrow_down"}
                  className="w-4 h-4 datepicker-button__arrow-box__icon"
                />
              </div>
            </button>
            {state.showDatePicker && (
              <div
                className="absolute right-0 z-20 transform translate-y-2 shadow-big rounded-md overflow-hidden"
                ref={datepickerRef}
              >
                <DateRangePicker
                  moveRangeOnFirstSelection={false}
                  // preview functionality is broken at build react-date-range@1.0.1
                  // source: https://github.com/Adphorus/react-date-range/issues/368
                  showPreview={false}
                  onChange={handlers.handleChangeDateRange}
                  months={1}
                  ranges={[state.range]}
                  direction="horizontal"
                  rangeColors={[TW_COLOR_BRAND]}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={cn(styleContentRow, "bg-gray-100 py-10")}>
        <ResponsiveContainer width={"100%"} height={250}>
          <AreaChart data={data.chart} layout="horizontal" margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorCalls" x1="0" y1="0" x2="0" y2="1">
                <stop offset="100%" stopColor={TW_COLOR_BRAND} stopOpacity={0.4} />
              </linearGradient>
            </defs>
            {/*
            bug: gap between yAxis label and surface left position
            issue: https://github.com/recharts/recharts/issues/2027
            */}
            <YAxis
              dataKey="calls"
              tick={{ fontSize: TW_FONT_SIZE_SM }}
              width={40}
              type={"number"}
              tickLine={false}
              tickMargin={5}
            />
            <XAxis
              dataKey="date"
              tick={{ fontSize: TW_FONT_SIZE_SM }}
              tickLine={false}
              tickMargin={15}
              // axisLine prop is not respected, will be fixed in the next recharts release
              // issue: https://github.com/recharts/recharts/pull/2006
              // axisLine={false}
            />
            <CartesianGrid />
            <Tooltip />
            <Area type="monotone" dataKey="calls" stroke={TW_COLOR_BRAND} fillOpacity={1} fill="url(#colorCalls)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      {/* Table */}
      <CSVLink ref={csvLinkRef} data={csvData} filename={"calls.csv"} className="hidden" />
      <div className={cn(styleContentRow, "relative pt-12 pb-8")}>
        <div className="flex items-center justify-between w-full">
          <Pagination
            page={data.pagination.page}
            perPage={data.pagination.perPage}
            count={data.pagination.count}
            onChange={handlers.handleChangePage}
          />
          <div className="flex w-auto">
            <Button icon={"download"} disabled={state.csvLoading} onClick={() => handleExportToCsv()}>
              Export to CSV
            </Button>
            <div className="relative">
              <Button icon={"filter"} className="ml-6" onClick={() => setIsFilterOpened(true)}>
                Filter
              </Button>
              {isFilterApplied(filters) && (
                <div className="absolute top-0 right-0 bg-red w-4 h-4 rounded-full transform -translate-y-2" />
              )}
            </div>
          </div>
        </div>
        {state.loading && (
          <div className="absolute inset-0 flex justify-center bg-white bg-opacity-75" style={{ zIndex: 1 }}>
            <LoadingBar className="transform translate-y-16" />
          </div>
        )}
        <DataTable<Call>
          noHeader
          keyField="id"
          columns={tableColumns}
          data={tableData}
          expandableRows
          className="mt-6"
          expandableInheritConditionalStyles={true}
          expandableRowsComponent={<ExpandableRowCall />}
          expandableIcon={{
            collapsed: (
              <div className="flex m-auto items-center justify-center arrow-box">
                <Icon type={"arrow_down"} className="w-4 h-4 arrow-box__icon" />
              </div>
            ),
            expanded: (
              <div className="flex m-auto items-center justify-center arrow-box">
                <Icon type={"arrow_up"} className="w-4 h-4 arrow-box__icon" />
              </div>
            ),
          }}
          onSort={handlers.handleChangeSort}
          sortServer
          sortIcon={<Icon type={"arrow_down"} className="ml-2" />}
          fixedHeader={true}
        />
        <div className="flex items-center justify-between w-full mt-4">
          <Pagination
            page={data.pagination.page}
            perPage={data.pagination.perPage}
            count={data.pagination.count}
            onChange={handlers.handleChangePage}
          />
          <div className="flex w-auto">
            <Button icon={"download"} disabled={state.csvLoading} onClick={() => handleExportToCsv()}>
              Export to CSV
            </Button>
            <div className="relative">
              <Button icon={"filter"} className="ml-6" onClick={() => setIsFilterOpened(true)}>
                Filter
              </Button>
              {isFilterApplied(filters) && (
                <div className="absolute top-0 right-0 bg-red w-4 h-4 rounded-full transform -translate-y-2" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Sidebar isVisible={isFilterOpened} ref={sidebarRef}>
        <div className="flex justify-between w-full flex-shrink-0">
          <span>Filter</span>
          <Button icon={"arrow_right"} onClick={() => setIsFilterOpened(false)} />
        </div>
        <Filter />
      </Sidebar>
    </div>
  );
};

/* Content row styles */
const styleContentRow: string = "px-2 md:px-20";

/*
 * expandableRowsComponent is not function with TS definition, it's React.ReactNode
 *
 * for typescript support is used hardcoded type Call
 * data prop is optional for disabled TS error about missing "data" props
 * */
const ExpandableRowCall = ({ data }: { data?: Call }) => {
  if (!data) return null;
  return (
    <div className="p-4">
      <div className="flex flex-wrap -m-2">
        <div className="w-full md:w-1/3 p-2">
          <div>
            <span className="text-gray-600">Number Name:</span>
            <span>{data.source_name || "-"}</span>
          </div>
          <div>
            <span className="text-gray-600">Qualified:</span>
            <span>{data.lead_status || "-"}</span>
          </div>
        </div>

        <div className="w-full md:w-1/3 p-2">
          <div>
            <span className="text-gray-600">Tags:</span>
            <span>
              {data.tags && data.tags.length > 0 ? data.tags.map((tag) => <Tag label={tag} className="mr-1" />) : "-"}
            </span>
          </div>
          <div>
            <span className="text-gray-600">Keyword Spotting:</span>
            <span>
              {data.keywords_spotted && data.keywords_spotted.length > 0 ? data.keywords_spotted.join(";") : "-"}
            </span>
          </div>
        </div>

        <div className="w-full md:w-1/3 p-2">
          <div>
            <span className="text-gray-600">Call Highlights:</span>
            <span>{data.highlights && data.highlights.length > 0 ? data.highlights.join(";") : "-"}</span>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="flex justify-center -mx-4">
          <div className="px-4">
            <a href={data.recording} title={"Download call record"} download>
              <Button icon={"download"} disabled={!data.recording}>
                Download
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ListenRecordingCell = ({ row }: { row: Call }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => setIsModalOpen(true), []);
  const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

  const modalTitle = useMemo(() => `${row.source_name} ${getFormattedStartTime(row.start_time)}`, [
    row.source_name,
    row.start_time,
  ]);

  return (
    <div>
      <Button icon={"headphones"} disabled={!row.recording} onClick={handleOpenModal} />

      {row.recording && isModalOpen && (
        <AudioModal title={modalTitle} audioSrc={row.recording} onClose={handleCloseModal} />
      )}
    </div>
  );
};
