// Commented code below causing https://github.com/tailwindcss/tailwindcss/issues/1853

/*
import resolveConfig from "tailwindcss/resolveConfig";

const tailwindConfig = require("./tailwind.config.js");

const fullConfig = resolveConfig(tailwindConfig);

export const TW_COLOR_BRAND: string = fullConfig.theme.colors.brand.default;
export const TW_FONT_SIZE_SM = fullConfig.theme.fontSize.sm[0];
*/

export const TW_COLOR_BRAND = "#3ECB8C";
export const TW_FONT_SIZE_SM = "12px";
